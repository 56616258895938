import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { GdprSettings, defaultGdprSettings } from '../../../../core/models';

@Component({
  selector: 'app-gdpr-settings',
  templateUrl: './gdpr-settings.component.html',
  styleUrls: ['./gdpr-settings.component.scss']
})
export class GdprSettingsComponent implements OnInit, OnChanges {

  @Input() settings: GdprSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: GdprSettings = {
    mail: [],
    ...defaultGdprSettings
  };
  form: FormGroup = new FormGroup( {
    mail: new FormArray([]),
    withdrawn: new FormControl<number>(this.value.withdrawn, [Validators.required, Validators.min(1), Validators.max(9200)]),
    dead_drafts: new FormControl<number>(this.value.dead_drafts, [Validators.required, Validators.min(1), Validators.max(300)]),
    inactive_profiles: new FormControl<number>(this.value.inactive_profiles, [Validators.required, Validators.min(1), Validators.max(300)]),
    refused_and_moved: new FormControl<number>(this.value.refused_and_moved, [Validators.required, Validators.min(1), Validators.max(9200)]),
    old_approved: new FormControl<number>(this.value.old_approved, [Validators.required, Validators.min(1), Validators.max(25)]),
    newly_approved: new FormControl<number>(this.value.newly_approved, [Validators.required, Validators.min(1), Validators.max(9200)]),
    newly_refused: new FormControl<number>(this.value.newly_refused, [Validators.required, Validators.min(1), Validators.max(9200)]),
    dangling: new FormControl<number>(this.value.dangling, [Validators.required, Validators.min(1), Validators.max(300)]),
    total_history: new FormControl<boolean>(this.value.total_history),
    attachment_keep_on_clean: new FormControl<boolean>(this.value.attachment_keep_on_clean),
    message_keep_on_clean: new FormControl<boolean>(this.value.message_keep_on_clean),
    report_keep_on_clean: new FormControl<boolean>((this.value.report_keep_on_clean)),
    purge_enabled: new FormControl<boolean>(this.value.purge_enabled),
    purge_dangling: new FormControl<number>(this.value.purge_dangling, [Validators.required, Validators.min(0)]),
    purge_refused: new FormControl<number>(this.value.purge_refused, [Validators.required, Validators.min(0)]),
    purge_approved: new FormControl<number>(this.value.purge_approved, [Validators.required, Validators.min(0)]),
    purge_log: new FormControl<number>(this.value.purge_log, [Validators.required, Validators.min(1)]),
    cleaner_disabled: new FormControl<boolean>(this.value.cleaner_disabled),
    inactive_ai_chats: new FormControl<number>(this.value.inactive_ai_chats, [Validators.required, Validators.min(1)])
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      let disabled = this.form.disabled;
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultGdprSettings, ...this.settings };
      }
      this.mails.clear();
      this.value.mail?.forEach(m => this.doAddMail());
      this.form.patchValue(this.value);
      if (disabled) {
        this.form.disable();
      }
    }
  }

  onCleanerDisabledChanged(): void {
    const dependentFields = ['refused_and_moved', 'old_approved', 'newly_approved', 'newly_refused', 'dangling',
      'purge_enabled', 'attachment_keep_on_clean', 'message_keep_on_clean', 'report_keep_on_clean', 'total_history'];
    const disable = this.isCleanerDisabled;
    if (disable) {
      this.form.patchValue({ purge_enabled: false });
    } else {
      this.form.patchValue({ ...this.value, cleaner_disabled: false });
    }
    dependentFields.forEach(field => {
      const ctrl = this.form.get(field) as FormControl;
      disable ? ctrl.disable() : ctrl.enable();
    });
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultGdprSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  doAddMail(): void {
    this.mails.push(new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]));
  }

  doRemoveMail(index: number): void {
    this.mails.removeAt(index);
  }

  get mails(): FormArray {
    return this.form.get('mail') as FormArray;
  }

  mailAt(index: number): FormControl {
    return this.mails.at(index) as FormControl;
  }

  purgeEnabled(): FormControl {
    return this.form.get('purge_enabled') as FormControl;
  }

  get isCleanerDisabled(): boolean {
    return this.form.value.cleaner_disabled;
  }

}
