<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      GDPR-indstillinger
      <mat-icon
      *ngIf="isCleanerDisabled    "
        matTooltip="Anonymisering deaktiveret (journaliseringssystem)."
        class="ml-3 cleaner-disabled-text"
      >warning</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="settings-view" *ngIf="form.disabled">
    <dl fxFlex="30" class="settings-list">
      <dt [ngClass]="{ 'cleaner-disabled-text': isCleanerDisabled }">
        Journaliseringssystem
      </dt>
      <dd [ngClass]="{ 'cleaner-disabled-text': isCleanerDisabled }">
        <span class="cleaner_disabled_value">{{value.cleaner_disabled ? 'Ja' : 'Nej'}}</span>
      </dd>

      <dt>Totalhistorik</dt>
      <dd><span class="total_history_value">{{value.total_history ? 'Ja' : 'Nej'}}</span></dd>

      <dt>Behold bilag ved anonymisering</dt>
      <dd><span class="attachment_keep_on_clean_value">{{value.attachment_keep_on_clean ? 'Ja' : 'Nej'}}</span></dd>

      <dt>Behold dialog ved anonymisering</dt>
      <dd><span class="message_keep_on_clean_value">{{value.message_keep_on_clean ? 'Ja' : 'Nej'}}</span></dd>

      <dt>Behold rapporter ved anonymisering</dt>
      <dd><span class="report_keep_on_clean_value">{{value.report_keep_on_clean ? 'Ja' : 'Nej'}}</span></dd>

      <dt>Sletning af inaktive profiler</dt>
      <dd><span class="inactive_profiles_value">{{value.inactive_profiles}}</span> måned(er)</dd>

      <dt>Slet urørte kladder</dt>
      <dd><span class="dead_drafts_value">{{value.dead_drafts}}</span> måned(er)</dd>

      <dt>Deaktivere urørte AI chats</dt>
      <dd><span class="inactive_profiles_value">{{value.inactive_ai_chats}}</span> måned(er)</dd>

      <dt>BCC på stamdataændringer</dt>
      <dd *ngIf="!value.mail?.length">Ingen.</dd>
      <dd *ngFor="let m of value.mail">{{m}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex=30 class="settings-list">
      <dt>Slet CV efter afslag</dt>
      <dd><span class="newly_refused_value">{{value.newly_refused}}</span> dag(e)</dd>

      <dt>Slet CV efter bevilling</dt>
      <dd><span class="newly_approved_value">{{value.newly_approved}}</span> dag(e)</dd>

      <dt>Anonymisering af tilbagetrukne sager</dt>
      <dd><span class="withdrawn">{{value.withdrawn}}</span> dag(e)</dd>

      <dt>Anonymisering af afviste og flyttede sager</dt>
      <dd><span class="refused_and_moved_value">{{value.refused_and_moved}}</span> dag(e)</dd>

      <dt>Anonymisering af ubehandlede sager</dt>
      <dd><span class="dangling_value">{{value.dangling}}</span> måned(er)</dd>

      <dt>Anonymisering af bevilgede sager</dt>
      <dd><span class="old_approved_value">{{value.old_approved}}</span> år</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="30" class="settings-list">
      <dt>Fuldstændig sletning af ansøgninger</dt>
      <dd><span class="purge_enabled">{{value.purge_enabled ? 'Ja' : 'Nej'}}</span></dd>

      <dt *ngIf="value.purge_enabled">Sletning af ubehandlede, anonymiserede sager</dt>
      <dd *ngIf="value.purge_enabled"><span class="purge_dangling">{{value.purge_dangling}}</span> dag(e)</dd>

      <dt *ngIf="value.purge_enabled">Sletning af afviste, anonymiserede sager</dt>
      <dd *ngIf="value.purge_enabled"><span class="purge_refused">{{value.purge_refused}}</span> dag(e)</dd>

      <dt *ngIf="value.purge_enabled">Sletning af bevilgede, anonymiserede sager</dt>
      <dd *ngIf="value.purge_enabled"><span class="purge_approved">{{value.purge_approved}}</span> dage(e)</dd>

      <dt *ngIf="value.purge_enabled">Sletning af slettede journalnumre</dt>
      <dd *ngIf="value.purge_enabled"><span class="purge_log">{{value.purge_log}}</span> år</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" >
      <div fxFlex="30">
        <div fxLayout="column" class="mb-3">
          <mat-checkbox class="mb-3" fxFill formControlName="cleaner_disabled" (change)="onCleanerDisabledChanged()">Brug GrantOne som journaliseringssystem.</mat-checkbox>
          <div *ngIf="isCleanerDisabled" class="cleaner-disabled-alert mt-2 p-2">
            <b>!!! OBS !!! OBS !!! OBS !!!</b>
            <div>
              Når GrantOne bruges som journaliseringssystem bliver ansøgninger <i>ikke</i> anonymiseret.
            </div>
          </div>
        </div>
        <div fxLayout="column" class="mb-3">
          <mat-checkbox class="mb-3" fxFill formControlName="total_history">Total historik (historik på anonymiserede sager).</mat-checkbox>
        </div>
        <div fxLayout="column" class="mb-3">
          <mat-checkbox class="mb-3" fxFill formControlName="attachment_keep_on_clean">Behold bilag ved anonymisering</mat-checkbox>
        </div>
        <div fxLayout="column" class="mb-3">
          <mat-checkbox class="mb-3" fxFill formControlName="message_keep_on_clean">Behold dialog ved anonymisering</mat-checkbox>
        </div>
        <div fxLayout="column" class="mb-3">
          <mat-checkbox class="mb-3" fxFill formControlName="report_keep_on_clean">Behold rapporter ved anonymisering</mat-checkbox>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Sletning af inaktive profiler</label>
          <mat-form-field>
            <input type="number" matInput formControlName="inactive_profiles">
            <span matSuffix>&nbsp;måneder</span>
            <mat-hint>Profiler er inaktive, hvis der ikke er en aktiv sag eller brugeren har været logget ind.</mat-hint>
            <mat-error *ngIf="form.controls['inactive_profiles'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['inactive_profiles'].hasError('min')">Mindst 1 måned.</mat-error>
            <mat-error *ngIf="form.controls['inactive_profiles'].hasError('max')">Højst 300 måneder (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Sletning af urørte kladder</label>
          <mat-form-field>
            <input type="number" matInput formControlName="dead_drafts">
            <span matSuffix>&nbsp;måneder</span>
            <mat-hint>Kladder er urørte, hvis de ikke er indsendt eller opdateret i en periode.</mat-hint>
            <mat-error *ngIf="form.controls['dead_drafts'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['dead_drafts'].hasError('min')">Mindst 1 måned.</mat-error>
            <mat-error *ngIf="form.controls['dead_drafts'].hasError('max')">Højst 300 måneder (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Deaktivere urørte AI chats</label>
          <mat-form-field>
            <input type="number" matInput formControlName="inactive_ai_chats">
            <span matSuffix>&nbsp;måneder</span>
            <mat-hint>AI Chats er urørte, hvis der ikke er chattet i en periode.</mat-hint>
            <mat-error *ngIf="form.controls['inactive_ai_chats'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['inactive_ai_chats'].hasError('min')">Mindst 1 måned.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <p>Ved ændring i væsentlige stamdata notificeres nedenstående mails</p>
          <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let _ of mails.controls; let i = index">
            <mat-form-field >
              <mat-label>E-mail</mat-label>
              <input
                type="email"
                matInput
                [formControl]="mailAt(i)"
              />
            </mat-form-field>
            <button
              mat-icon-button
              matTooltip="Slet e-mail"
              (click)="doRemoveMail(i)"
            ><mat-icon>delete</mat-icon></button>
          </div>
          <div>
            <button
              mat-raised-button
              color="primary"
              matTooltip="Tilføj e-mail"
              (click)="doAddMail()"
            >Tilføj e-mail</button>
          </div>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="30">
        <div fxLayout="column" class="mb-3">
          <label>CV-oprydning ved afslag</label>
          <mat-form-field>
            <input type="number" matInput formControlName="newly_refused">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Sletning af CV efter afsendt afslag.</mat-hint>
            <mat-error *ngIf="form.controls['newly_refused'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['newly_refused'].hasError('min')">Mindst 1 dag.</mat-error>
            <mat-error *ngIf="form.controls['newly_refused'].hasError('max')">Højst 9.200 dage (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>CV-oprydning ved bevilling</label>
          <mat-form-field>
            <input type="number" matInput formControlName="newly_approved">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Sletning af CV efter godkendt bevilling.</mat-hint>
            <mat-error *ngIf="form.controls['newly_approved'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['newly_approved'].hasError('min')">Mindst 1 dag.</mat-error>
            <mat-error *ngIf="form.controls['newly_approved'].hasError('max')">Højst 9.200 dage (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Anonymisering af tilbagetrukne sager</label>
          <mat-form-field>
            <input type="number" matInput formControlName="withdrawn">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Ansøgningerne anonymiseres helt efter en karensperiode.</mat-hint>
            <mat-error *ngIf="form.controls['withdrawn'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['withdrawn'].hasError('min')">Mindst 1 dag.</mat-error>
            <mat-error *ngIf="form.controls['withdrawn'].hasError('max')">Højst 9.200 dage (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Anonymisering af afslag/flytning</label>
          <mat-form-field>
            <input type="number" matInput formControlName="refused_and_moved">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Anonymisering af sager efter afslag eller flytning til anden fond.</mat-hint>
            <mat-error *ngIf="form.controls['refused_and_moved'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['refused_and_moved'].hasError('min')">Mindst 1 dag.</mat-error>
            <mat-error *ngIf="form.controls['refused_and_moved'].hasError('max')">Højst 9.200 dage (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Anonymisering af ubehandlede</label>
          <mat-form-field>
            <input type="number" matInput formControlName="dangling">
            <span matSuffix>&nbsp;måneder</span>
            <mat-hint>Anonymisering af udbehandlere sager.</mat-hint>
            <mat-error *ngIf="form.controls['dangling'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['dangling'].hasError('min')">Mindst 1 måned.</mat-error>
            <mat-error *ngIf="form.controls['dangling'].hasError('max')">Højst 300 måneder (25 år).</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3">
          <label>Anonymisering af bevilgede</label>
          <mat-form-field>
            <input type="number" matInput formControlName="old_approved">
            <span matSuffix>&nbsp;år</span>
            <mat-hint>Anonymisering af bevilgede sager efter sidste udbetaling.</mat-hint>
            <mat-error *ngIf="form.controls['old_approved'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['old_approved'].hasError('min')">Mindst 5 år pga Skat.</mat-error>
            <mat-error *ngIf="form.controls['old_approved'].hasError('max')">Højst 25 år.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="30">
        <div fxLayout="column" class="mb-3">
          <mat-checkbox formControlName="purge_enabled">Fuldstændig sletning af ansøgninger</mat-checkbox>
          <div *ngIf="purgeEnabled().value" class="purge-enabled-alert mt-2 p-2">
            <b>!!! OBS !!! OBS !!! OBS !!!</b>
            <div>
              Når fuldstændig sletning af sager er aktiveret slettes sagerne helt fra databasen,
              jf. nedenstående opsætning. Det er ikke muligt at genskabe sagerne, når de er slettet.
            </div>
          </div>
        </div>
        <div fxLayout="column" class="mb-3" *ngIf="purgeEnabled().value">
          <label>Sletning af ubehandlede, anonymiserede sager</label>
          <mat-form-field>
            <input type="number" matInput formControlName="purge_dangling">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Endelig sletning af ubehandlede sager efter anonymisering.</mat-hint>
            <mat-error *ngIf="form.controls['purge_dangling'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['purge_dangling'].hasError('min')">Mindst 1 dag.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3" *ngIf="purgeEnabled().value">
          <label>Sletning af afviste, anonymiserede sager</label>
          <mat-form-field>
            <input type="number" matInput formControlName="purge_refused">
            <span matSuffix>&nbsp;dag(e)</span>
            <mat-hint>Endelig sletning af afviste sager efter anonymisering.</mat-hint>
            <mat-error *ngIf="form.controls['purge_refused'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['purge_refused'].hasError('min')">Mindst 1 dag.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3" *ngIf="purgeEnabled().value">
          <label>Sletning af bevilgede, anonymiserede sager</label>
          <mat-form-field>
            <input type="number" matInput formControlName="purge_approved">
            <span matSuffix>&nbsp;år</span>
            <mat-hint>Endelig sletning af bevilgede sager efter anonymisering.</mat-hint>
            <mat-error *ngIf="form.controls['purge_approved'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['purge_approved'].hasError('min')">Mindst 1 år.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="mb-3" *ngIf="purgeEnabled().value">
          <label>Slettelog levetid</label>
          <mat-form-field>
            <input type="number" matInput formControlName="purge_log">
            <span matSuffix>&nbsp;år</span>
            <mat-hint>Hvor mange år journalnumre gemmes efter sletningen.</mat-hint>
            <mat-error *ngIf="form.controls['purge_log'].hasError('required')">Obligatorisk.</mat-error>
            <mat-error *ngIf="form.controls['purge_log'].hasError('min')">Mindst 5 år.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" class="action-edit" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" class="action-cancel" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" class="action-save" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
